import React from 'react'
import { formatRoute } from 'react-router-named-routes'
import { parse } from 'date-fns'

import { ShareOrderLinkWithCopy } from 'components/newOrderWorkflow/shareOrderLink/ShareOrderLink'
import DateTimeFormatted from 'components/newOrderWorkflow/shared/dateTimeFormatted/DateTimeFormatted'
import { formatTime, formatDate } from 'utils/datetime'
import { formatAddress } from 'utils/formatters'
import { shareLinkBaseUrl } from 'utils/order'
import { ATTENDEE_TOKEN_PATH, EDIT_GROUP_ORDER_PATH } from 'routes'
import { Button } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles(theme => ({
  manageOrderButton: {
    backgroundColor: theme.color.brandPrimaryDark,
    color: '#FFFFFF',
    '&:hover': {
      backgroundColor: theme.color.buttonHover,
    },
  },
}))

const OrderInfo = ({ groupOrder, dropoffLocation, onClickAction }) => {
  const classes = useStyles()

  return (
    <div className='order-info'>
      <div className='order-info__header'>
        <div className='order-info__title' style={{ marginTop: '0.5em' }}>
          Order #{groupOrder.id}
        </div>
        <Button
          onClick={onClickAction}
          size='small'
          variant='contained'
          className={classes.manageOrderButton}
        >
          Manage Order
        </Button>
      </div>

      <div className='order-info__panel'>
        <div className='dfo-title'>Group Order</div>

        <div className='order-info__section'>
          <DateTimeFormatted
            date={parse(groupOrder.dropoff, "yyyy-MM-dd'T'HH:mm:ss", new Date())}
            boldFont={false}
          />

          <span className='order-info__attendees'>
            &nbsp;for&nbsp;
            <span className='order-info__attendees-count'>{groupOrder.attendees.length}&nbsp;</span>
            people
          </span>
        </div>

        <div className='order-info__section'>
          to&nbsp;
          <span className='order-info__location'>{formatAddress(dropoffLocation)}</span>
        </div>

        <div className='order-info__section'>
          by&nbsp;
          <span className='order-info__store'>{groupOrder.store.name}</span>
        </div>
      </div>
    </div>
  )
}

let ShareOrderMain = ({ history, groupOrder, currentLocation }) => {
  if (!groupOrder || !currentLocation) {
    return null
  }

  const { cutoff, attendeeUrlToken } = groupOrder

  const shareLink = `${shareLinkBaseUrl}${formatRoute(ATTENDEE_TOKEN_PATH, {
    token: attendeeUrlToken,
  })}`

  return (
    <div className='share-order-main'>
      <div className='share-order-main__content'>
        <h1 className='dfo-h1'>Send this link to your meeting attendees!</h1>

        <div className='share-order-main__instructions'>
          Attendees must make their meal selections by <b>{formatTime(cutoff)}</b> on{' '}
          <b>{formatDate(cutoff)}</b>, otherwise they will receive the backup meal.
        </div>

        <ShareOrderLinkWithCopy url={shareLink} />

        <OrderInfo
          groupOrder={groupOrder}
          dropoffLocation={currentLocation}
          onClickAction={() =>
            history.push(
              formatRoute(EDIT_GROUP_ORDER_PATH, {
                groupOrderId: groupOrder.id,
              }),
            )
          }
        />
      </div>
    </div>
  )
}

export default ShareOrderMain
