import React, { Component } from 'react'
import { connect } from 'react-redux'
import isEmpty from 'lodash/isEmpty'
import size from 'lodash/size'

import DfoLayout from 'components/newOrderWorkflow/dfoLayout/DfoLayout'
import OrderWorkflowLayout from 'components/newOrderWorkflow/orderWorkflowLayout/OrderWorkflowLayout'
import MenuPage from 'components/newOrderWorkflow/menuPage/MenuPage'
import MenuHeader from 'components/newOrderWorkflow/menuPage/menuHeader/MenuHeader'
import AbandonOrderPrompt from 'components/newOrderWorkflow/shared/AbandonOrderPrompt'
import NewBackupMealSidebar from './newBackupMealSidebar/NewBackupMealSidebar'
import RestaurantNotAvailableModal from 'components/newOrderWorkflow/shared/restaurantNotAvailableModal/RestaurantNotAvailableModal'
import BackupMealWarningModal from './backupMealWarningModal/BackupMealWarningModal'
import LoginModal from 'components/login/LoginModal'
import {
  newGroupOrder,
  selectAccessToken,
  selectMeal,
  selectNewGroupOrder,
  selectNewGroupOrderStore,
  selectLocation,
  selectRestaurantNotAvailable,
  selectBackupMealWarningAcknowledged,
  selectCurrentUser,
} from 'redux/selectors'
import { getStores } from 'redux/modules/store'
import { getMenuItem } from 'redux/modules/menu'
import { setNavAlert } from 'redux/modules/alerts'
import {
  saveDefaultMeal,
  setRestaurantNotAvailable,
  setBackupMealWarningAcknowledged,
  setDeliveryOptionsFormOpen,
} from 'redux/modules/newGroupOrder'
import { routeToHomeIfNoData } from 'higherOrderComponents'
import { STORES_PATH } from 'routes'
import { sumMealItems } from 'utils/order'
import { BACKUP_MEAL_ORDER_STEP } from 'components/newOrderWorkflow/shared/orderSteps'
import {
  INCOMPLETE_STEP_ALERT,
  buildIncompleteStepAlert,
} from 'components/newOrderWorkflow/navAlerts/constants'

class NewBackupMealPage extends Component {
  state = {
    selectedMenuItemId: null,
    showLogin: false,
    mealChanged: false,
    submitting: false,
    submitAttempted: false,
  }

  componentDidMount () {
    this.props.getStores(this.props.newGroupOrder.locationId)
  }

  componentDidUpdate (prevProps) {
    if (prevProps.meal !== this.props.meal) {
      this.setState({ mealChanged: true })
    }
  }

  componentWillUnmount () {
    if (this.state.mealChanged && this.isValidMeal(this.props.meal) && !!this.props.accessToken) {
      this.saveMeal(this.props.meal, false)
    }
    this.props.setRestaurantNotAvailable({})
  }

  handleSaveMeal = meal => {
    if (!!this.props.accessToken) {
      this.setState({ submitAttempted: true })
      if (this.isValidMeal(meal)) {
        this.setState({ submitting: true })
        this.saveMeal(meal)
      }
    } else {
      this.setState({ showLogin: true })
    }
  }

  onLoginSuccess = () => {
    this.setState({ showLogin: false })
    this.handleSaveMeal(this.props.meal)
  }

  saveMeal = async (meal, shouldRedirect = true) => {
    this.setState({ mealChanged: false })
    await this.props.saveDefaultMeal(meal, this.props.history, shouldRedirect)
    this.setState({ submitting: false })
  }

  viewAll = () => this.props.history.push(STORES_PATH)

  isValidMeal = meal => !isEmpty(meal)

  handleSelectMenuItem = menuItemId => {
    if (
      !this.props.backupMealWarningAcknowledged &&
      size(this.props.meal) > 1 &&
      sumMealItems(this.props.meal).intValue >= 2000
    ) {
      this.setState({ selectedMenuItemId: menuItemId })
      return false
    }

    return true
  }

  handleBackupMealWarningAcknowledged = async () => {
    await this.props.getMenuItem(this.state.selectedMenuItemId)
    this.props.setBackupMealWarningAcknowledged(true)
    this.setState({ selectedMenuItemId: null })
  }

  render () {
    const {
      currentUser,
      meal,
      newGroupOrder,
      currentStore,
      location,
      deliveryOptionsValid,
      restaurantNotAvailable,
      setRestaurantNotAvailable,
      setDeliveryOptionsFormOpen,
      setNavAlert,
    } = this.props

    const { submitting, submitAttempted, selectedMenuItemId, showLogin } = this.state

    if (!currentStore) {
      return null
    }

    const isValid = this.isValidMeal(meal)

    return (
      <DfoLayout currentUser={currentUser}>
        <MenuPage
          isBackupMeal
          initialMeal={newGroupOrder.defaultMeal || meal || []}
          groupOrder={newGroupOrder}
          currentStore={currentStore}
          onSelectMenuItem={this.handleSelectMenuItem}
          saveMeal={this.handleSaveMeal}
          isValid={isValid}
          submitting={submitting}
          submitAttempted={submitAttempted}
          layoutComponent={props => (
            <OrderWorkflowLayout
              headerProps={{
                stepName: BACKUP_MEAL_ORDER_STEP,
                onClickFutureStep: (step, isComplete) => {
                  if (!isValid) {
                    setNavAlert({
                      name: INCOMPLETE_STEP_ALERT,
                      messageOverride: buildIncompleteStepAlert(BACKUP_MEAL_ORDER_STEP),
                    })
                  } else if (isComplete) {
                    this.props.history.push(step.path)
                  }
                },
                onClickNextStep: step => {
                  if (!isValid) {
                    setNavAlert({
                      name: INCOMPLETE_STEP_ALERT,
                      messageOverride: buildIncompleteStepAlert(BACKUP_MEAL_ORDER_STEP),
                    })
                  } else {
                    this.handleSaveMeal(this.props.meal)
                  }
                },
                showNextStepLabel: isValid,
              }}
              {...props}
            />
          )}
          menuHeaderComponent={props => <MenuHeader {...props} />}
          sidebarComponent={props => (
            <NewBackupMealSidebar
              currentLocation={location}
              currentStore={currentStore}
              setDeliveryOptionsFormOpen={setDeliveryOptionsFormOpen}
              deliveryOptionsValid={deliveryOptionsValid}
              {...props}
            />
          )}
        />

        <RestaurantNotAvailableModal
          showModal={
            !isEmpty(restaurantNotAvailable) &&
            (restaurantNotAvailable.attendeesCount || restaurantNotAvailable.time)
          }
          type={restaurantNotAvailable}
          onModify={() => setRestaurantNotAvailable({})}
          onViewAll={this.viewAll}
          maxCount={currentStore.maxHeadCount}
        />

        <BackupMealWarningModal
          showModal={!!selectedMenuItemId}
          onAcknowledge={this.handleBackupMealWarningAcknowledged}
        />

        <LoginModal
          show={showLogin}
          onCancel={() => this.setState({ showLogin: false })}
          onSuccess={this.onLoginSuccess}
        />

        <AbandonOrderPrompt />
      </DfoLayout>
    )
  }
}

NewBackupMealPage = routeToHomeIfNoData(NewBackupMealPage)

const mapStateToProps = state => ({
  currentUser: selectCurrentUser(state),
  accessToken: selectAccessToken(state),
  meal: selectMeal(state),
  newGroupOrder: selectNewGroupOrder(state),
  currentStore: selectNewGroupOrderStore(state),
  location: selectLocation(state),
  deliveryOptionsValid: newGroupOrder.selectDeliveryOptionsValid(state),
  restaurantNotAvailable: selectRestaurantNotAvailable(state),
  backupMealWarningAcknowledged: selectBackupMealWarningAcknowledged(state),
})

const mapDispatchToProps = {
  getStores,
  getMenuItem,
  setNavAlert,
  saveDefaultMeal,
  setRestaurantNotAvailable,
  setBackupMealWarningAcknowledged,
  setDeliveryOptionsFormOpen,
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(NewBackupMealPage)
